<template>
  <div id="Login">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 pt-3  bg-lines bg-cover bg-center bg-fixed">
      <h1 class="brand-color-dark">Einstellungen</h1>

      <div class="nav-content-container flex-none md:flex gap-2">
        <div class="nav bg-card-content w-full md:w-1/3 rounded-lg flex flex-col justify-between mb-2">
            <div class="card-content">
                <div class="head p-6">

                  <!-- <div class="circular--portrait">
                    <img src="https://firebasestorage.googleapis.com/v0/b/stadtbahnfahrerclub.appspot.com/o/userContent%2FMILm6fJlOjPtdZGmTdArzMrWwdp2%2Faccount%2Fimg.jpg?alt=media" alt="Avatar">
                  </div> -->

                    <p class="text-center username font-bold">{{user.first_name}} {{user.last_name}}</p>
                    <p class="text-center">{{user.company.name}}</p>
                    <div class="badge badge-brand">BETA-MEMBER</div>
                </div>
                

                <div class="menu my-14">
                    <router-link to="konto" active-class="active">Konto</router-link>
                    <!-- <router-link to="fahrzeuge" active-class="active">Fahrzeuge</router-link> -->
                    <router-link to="benachrichtigungen" active-class="active">Benachrichtigungen</router-link>
                    <router-link to="persoenlich" active-class="active">Persönliche Einstellungen</router-link>
                    <router-link to="privatsphaere" active-class="active">Privatsphäre</router-link>
                    <router-link to="telegram" active-class="active">Telegram Bot</router-link>
                </div>

                
            </div>
            <div class="card-footer text-center mt-4 px-6">
                <button @click="logout" class="btn-designless rounded-lg bg-black p-3"><span class="color-danger text-red-700 hover:text-red-600 font-bold"><font-awesome-icon icon="sign-out-alt" class=""/> Abmelden</span></button><br>
                <p class="text-center input-helper-text text-gray-700  pb-3">Von Stadtbahnfahrer für Stadtbahnfahrer <font-awesome-icon icon="heart" class="color-danger text-red-800"/></p>
            </div>
        </div>
        <div class="content bg-card-content rounded-lg w-full mb-2">
            <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader";

export default {
  name: "Login",
  components: {
    TheHeader,
    SiteNav,
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped>
.circular--portrait {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}

.menu {
  @apply flex flex-col items-stretch;
}

.nav-content-container .nav .menu {
    margin-top: 2rem;
}

.nav-content-container .nav .menu a {
    background-color: #2D3142; 
    color: white; 
    display: block; 
    padding: 12px; 
    text-decoration: none;
    @apply text-center md:text-left;
}

.nav-content-container .nav .menu a:hover {
    background-color: rgb(136, 136, 136); 
}

.nav-content-container .nav .menu a.active {
    background-color: #17387E; 
    color: white;
}


.nav-content-container .nav .menu a:not(:last-child) {
    border-bottom: #17507e 1px solid;  
}



</style>